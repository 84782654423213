import { FC, useEffect, useState } from 'react';

import Cookies from 'js-cookie';

import RichText from '@components/RichText/RichText';
import { useContent } from '@sitecore/common';
import { CookieWallRendering } from '@sitecore/types/CookieWall';
import { AlertDialog, TextLink } from '@sparky';

const qubitConsentVersion = '2.0.0';
const privacyConsentVersion = '1';

const CookieWall: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { f } = useContent<CookieWallRendering>();

  const handleSetCookie = (accept: boolean) => {
    const qubitValue = accept ? 'Accepted' : 'Declined';
    const privacyValue = accept ? 'true' : 'false';
    Cookies.set('qubitconsent', qubitValue, { expires: 365 });
    Cookies.set('qubitconsent_version', qubitConsentVersion, { expires: 365 });
    Cookies.set('privacyConsent', privacyValue, { expires: 365 });
    Cookies.set('privacyConsent_type', 'explicit', { expires: 365 });
    Cookies.set('privacyConsent_version', privacyConsentVersion, { expires: 365 });
  };

  useEffect(() => {
    const cookiesExists =
      Cookies.get('qubitconsent') && Cookies.get('privacyConsent') && Cookies.get('privacyConsent_type');
    const cookiesVersionMisMatch =
      Cookies.get('qubitconsent_version') !== qubitConsentVersion ||
      Cookies.get('privacyConsent_version') !== privacyConsentVersion;

    if (!cookiesExists || cookiesVersionMisMatch) {
      setIsOpen(true);
    }

    // Event listener to capture clicks on the re-open link
    const handleClick = (event: Event) => {
      event.preventDefault();
      setIsOpen(true);
    };

    // Find all anchor elements that have '#cookiepermissionmodal' in their href attribute
    const elements = Array.from(document.querySelectorAll('a[href*="#cookiepermissionmodal"]')) as HTMLElement[];
    elements.forEach(element => {
      element.addEventListener('click', handleClick);
    });

    // Cleanup event listener on component unmount
    return () => {
      elements.forEach(element => {
        element.removeEventListener('click', handleClick);
      });
    };
  }, []);

  return (
    <AlertDialog
      confirmText={f('yesButtonText.value')}
      denyText={f('noButtonText.value')}
      disableBackgroundInteraction={false}
      isOpen={isOpen}
      onConfirm={() => handleSetCookie(true)}
      onDeny={() => handleSetCookie(false)}
      setOpen={setIsOpen}
      title={f('title.value')}>
      <RichText
        html={f('description.value')}
        replacements={{
          a: props => <TextLink {...props} />,
        }}
      />
    </AlertDialog>
  );
};

export default CookieWall;
