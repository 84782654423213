import React from 'react';

import { TextLink } from '@sparky';

import { useChat } from './useChat';

interface Props {
  'data-chattopic': string;
  children?: React.ReactNode;
}

const ChatLinkButton: React.FC<Props> = ({ 'data-chattopic': topic, children }) => {
  const [mount] = useChat();

  return <TextLink onClick={() => mount({ visibility: 'open', topic })}>{children}</TextLink>;
};

export default ChatLinkButton;
